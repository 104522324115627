import axios from 'axios'
import { getDefaultOptions } from './auth'
import { getUrl } from './getUrl'
import { handleApi } from './handleApiCall'

export async function getFeedback({ feedback_id, viewAs }: { feedback_id: string; viewAs?: string }) {
	return await handleApi(
		axios.get<{
			feedback: { heading: string; type: string; questions: string[] }
			submissions: { answers: number[]; length: undefined } | { answers: number[] }[]
			submission_list: any[]
		}>(getUrl(`feedback/?feedback_id=${feedback_id}&asTeacher=${viewAs === 'Teacher'}`), getDefaultOptions())
	)
}

export async function createFeedback(body: { questions: string[]; course: string; heading: string; type: string; module: string | null }) {
	return await handleApi(axios.post<{ uid: string }>(getUrl(`feedback/`), { ...body }, { ...getDefaultOptions() }))
}

export async function submitFeedback(body: { feedback_uid: string; answers: number[]; course_uid: string }) {
	return await handleApi(axios.post<{ uid: string }>(getUrl(`feedback/submit`), { ...body }, { ...getDefaultOptions() }))
}
